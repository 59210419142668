import _ from 'lodash';
import queryString from 'query-string';

export const buildQuery = ( // eslint-disable-line
  params,
) =>
  queryString.stringify(
    _.pickBy(
      params,
      value =>
        (!_.isEmpty(value) || _.isNumber(value) || _.isBoolean(value)) &&
        value !== undefined,
    ),
    { arrayFormat: 'bracket' },
  );

export const isLoggedIn = user => !!(user && user.user && user.user._id);

export const isDealer = user =>
  user && user.user && ['dealer', 'admin'].includes(user.user.role);

export const isAdmin = user =>
  user && user.user && ['admin'].includes(user.user.role);

export const isOwner = (user, createdBy, includeAdmin = true) =>
  user &&
  user.user &&
  (user.user._id === createdBy || (includeAdmin && user.user.role === 'admin'));

export const toggleCheckRow = (selected, item) => {
  const selectedIndex = _.findIndex(selected, { _id: item._id });
  let newSelected = [];

  if (selectedIndex === -1) {
    newSelected = newSelected.concat(selected, item);
  } else if (selectedIndex === 0) {
    newSelected = newSelected.concat(selected.slice(1));
  } else if (selectedIndex === selected.length - 1) {
    newSelected = newSelected.concat(selected.slice(0, -1));
  } else if (selectedIndex > 0) {
    newSelected = newSelected.concat(
      selected.slice(0, selectedIndex),
      selected.slice(selectedIndex + 1),
    );
  }

  return newSelected;
};

export const parseSort = field => {
  let order = 'asc';
  let orderBy = field;
  if (orderBy && orderBy.indexOf('-') === 0) {
    order = 'desc';
    orderBy = orderBy.replace('/-/gi', '');
  }

  return { order, orderBy };
};

export const createPasswordLabel = score => {
  switch (score) {
    case 0:
      return 'Weak';
    case 1:
      return 'Weak';
    case 2:
      return 'Fair';
    case 3:
      return 'Good';
    case 4:
      return 'Strong';
    default:
      return 'Weak';
  }
};
