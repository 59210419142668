exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2Gxun{float:right;margin:6px 0 0}@media (max-width: 767px){._2Gxun{float:none}}._12ku7{cursor:pointer;display:inline-block;font-size:1.125em;padding:3px 8px;text-decoration:none}._12ku7,._12ku7:active,._12ku7:visited{color:rgba(255,255,255,0.6)}._12ku7:hover{color:#fff}._2MVOT{color:rgba(255,255,255,0.3)}\n", ""]);

// exports
exports.locals = {
	"root": "_2Gxun",
	"link": "_12ku7",
	"spacer": "_2MVOT"
};